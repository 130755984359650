import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, Inject } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
    selector: 'app-token',
    templateUrl: 'token.component.html',
    styleUrls: ['./token.component.css']
})
export class TokenComponent {
    data: any = {};

    constructor(
        public dialogRef: MatDialogRef<TokenComponent>,
        private utils: UtilsService,
        @Inject(MAT_DIALOG_DATA) public defaults: any,
    ) {
        this.renderToken();
    }

    
    renderToken() {
        this.data.token = this.utils.createToken();
    }

    
    save() {
        const acesso = {
            ...this.data,
        };
        this.dialogRef.close(acesso);
    }

    toast() {
        this.utils.toast().message('Por favor, preencha todas as informações');
    }
}
