import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { first, map, take, tap } from 'rxjs/operators';
import { UtilsService } from './utils.service';
import { Observable } from 'rxjs';
@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private utils: UtilsService,
    private router: Router
  ) { }

  canActivate(
  ): Observable<boolean> | Promise<boolean> | boolean {

    return this.utils.firebaseAuth.authState
      .pipe(
        take(1),
        map((authState) => !!authState),
        tap(authenticated => {
          if (!authenticated) {
            this.router.navigate(['/']);
          }
        }),
        first());
  }
}
