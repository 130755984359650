
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatNativeDateModule } from '@angular/material/core';
import { MatListModule } from '@angular/material/list';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule, MatAutocompleteModule, MatChipsModule } from '@angular/material';
import { MatTreeModule } from '@angular/material/tree';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AutocompleteComponent } from '../components/auto-complete/auto-complete.component';
import { TextMaskModule } from 'angular2-text-mask';

@NgModule({
    imports: [
        CommonModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        FormsModule,
        ReactiveFormsModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatCheckboxModule,
        MatInputModule,
        MatAutocompleteModule,
        MatIconModule,
        MatMenuModule,
        MatButtonModule,
        MatDialogModule,
        MatRadioModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatSliderModule,
        MatTooltipModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatTabsModule,
        MatButtonToggleModule,
        MatFormFieldModule,
        MatTreeModule,
        TextMaskModule,
        MatProgressBarModule,
        MatChipsModule,
        FlexLayoutModule,
        MatSidenavModule,
        MatToolbarModule,
        MatListModule
    ],
    exports: [
        MatProgressSpinnerModule,
        MatSnackBarModule,
        FormsModule,
        ReactiveFormsModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatCheckboxModule,
        MatInputModule,
        FlexLayoutModule,
        MatSidenavModule,
        MatIconModule,
        MatMenuModule,
        MatButtonModule,
        MatAutocompleteModule,
        MatDialogModule,
        MatChipsModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatSliderModule,
        MatTooltipModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatTabsModule,
        MatButtonToggleModule,
        MatFormFieldModule,
        MatTreeModule,
        MatProgressBarModule,
        TextMaskModule,
        AutocompleteComponent,
        MatToolbarModule,
        MatListModule
    ],
    entryComponents: [
        AutocompleteComponent,
    ],
    declarations: [
        AutocompleteComponent,
    ],
})
export class SharedModule { }
