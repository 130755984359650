import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, Inject } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';
import * as moment from 'moment';

@Component({
    selector: 'app-person-vip',
    templateUrl: 'person-vip.component.html',
    styleUrls: ['./person-vip.component.css']
})
export class PersonVIPComponent {
    data: any = {};

    constructor(
        public dialogRef: MatDialogRef<PersonVIPComponent>,
        private utils: UtilsService,
        @Inject(MAT_DIALOG_DATA) public defaults: any,
    ) {
        this.renderToken()
    }

    renderToken() {
        this.data.token = this.utils.createToken();
    }


    save() {
        const email = this.data.email.toString().trim().toLocaleLowerCase();
        console.log('Creating vip: ', email);

        const acesso = {
            ...this.data,
            email,
            canCreateToken: this.data.limite !== 0,
            ativo: true,
            canCreateVIP: false,
            canDownload: false,
            canSeeAll: false,
            canSeeYourTeam: true,
            canExpire: true,
            dataVencimento: moment(this.data.dataVencimento).format('YYYY-MM-DD'),
            tokens: [],
            person: {},
        };
        this.dialogRef.close(acesso);
    }

    toast() {
        this.utils.toast().message('Por favor, preencha todas as informações');
    }
}
