import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutComponent } from './theme/layout/layout.component';
import { AdminComponent } from './theme/admin/admin.component';
import { MenuComponent } from './components/menu/menu.component';
import { UtilsService } from './services/utils.service';
import { SharedModule } from './shared/shared.module';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { DialogConfirmComponent } from './components/dialog-confirm/dialog-confirm.component';
import { AuthGuard } from './services/authguard.service';
import { InterceptorService } from './services/interceptor.service';
import { CEPService } from './services/cep.service.';
import { TokenComponent } from './pages/token/token.component';
import { PropostaComponent } from './pages/proposta/proposta.component';
import { PersonVIPComponent } from './pages/person-vip/person-vip.component';
import { TextMaskModule } from 'angular2-text-mask';

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    AdminComponent,
    MenuComponent,
    DialogConfirmComponent,
    TokenComponent,
    PersonVIPComponent,
    PropostaComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'sistema' }),
    SharedModule,
    HttpClientModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireMessagingModule,
    AngularFirestoreModule,
    BrowserAnimationsModule,
    TextMaskModule
  ],
  providers: [
    UtilsService,
    AuthGuard,
    CEPService,
    { provide: LOCALE_ID, useValue: 'pt' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    DialogConfirmComponent,
    TokenComponent,
    PropostaComponent,
    PersonVIPComponent,
  ]
})
export class AppModule { }
