// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  endpoint: 'http://localhost:5000/equipe10x/us-central1',
  firebase: {
    apiKey: 'AIzaSyCoLyNmfNG-IRCC6O_P_Z7OBhif2aGV-nw',
    authDomain: 'equipe10x.firebaseapp.com',
    databaseURL: 'https://equipe10x.firebaseio.com',
    projectId: 'equipe10x',
    storageBucket: 'equipe10x.appspot.com',
    messagingSenderId: '593801523987',
    appId: 'equipe10x'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
