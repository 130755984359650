
import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';

@Injectable()
export class CEPService {

    constructor(
        private utils: UtilsService,
    ) {
    }

    getEndereco(cep) {
        const endpoint = `https://viacep.com.br/ws/${cep}/json/`;
        return this.utils.httpGET(endpoint);
    }

    // getCoordenadas(endereco) {
    //     const endpoint = `https://maps.googleapis.com/maps/api/geocode/json?address=${endereco}&key=${this.utils.googleMapsApiKey}`;
    //     return this.utils.httpGET(endpoint);
    // }
}
