import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UtilsService } from './utils.service';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class InterceptorService implements HttpInterceptor {

    constructor(
        private authService: UtilsService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.authService.getToken().pipe
            (mergeMap((token) => {
                if (request.url.indexOf(this.authService.endpoint) === -1) {
                    return next.handle(request);
                }
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${token.value}`,
                    }
                });
                return next.handle(request);
            }));
    }
}
