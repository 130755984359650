// tslint:disable: no-use-before-declare
import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { interval } from 'rxjs';

@Component({
    selector: 'app-autocomplete',
    templateUrl: './auto-complete.component.html',
    styleUrls: ['./auto-complete.component.scss']
})
export class AutocompleteComponent implements OnInit, OnChanges {

    stateCtrl: FormControl;
    reactiveStates: any;
    @Input() placeholder = '';
    @Input() loading = false;
    @Input() showId = true;
    @Input() required = false;
    @Input() disabled = false;
    @Input() hidden = false;
    @Input() time = 0;
    @Input() wait = 3;
    @Input() value = '';
    @Input() text = '';
    @Input() list = null;
    @Output() loadmore: EventEmitter<any> = new EventEmitter();
    @Output() noHas: EventEmitter<any> = new EventEmitter();
    @Output() valueChange = new EventEmitter();
    states = [];
    subscription;

    constructor() {
    }


    ngOnInit() {
    }

    ngOnChanges(changes) {
        if (changes.list) {
            this.states = this.list;
        }
        if (changes.value) {
            this.stateCtrl = new FormControl({ value: this.value, text: this.text });
        }
    }

    onSelect(e) {
        this.value = e.value;
        this.text = e.text;
        this.valueChange.emit(e);
    }

    startCronometro() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        const source = interval(250);
        this.subscription = source.subscribe(x => {
            this.time++;
            if (this.time >= this.wait) {
                this.stopCronometro();
                this.time = 0;
                this.loadmore.emit(this.stateCtrl.value);
            }
        });
    }

    stopCronometro() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    filterStates(val: string) {
        return val ? this.states.filter(s => new RegExp(`^${val}`, 'gi').test(s.text))
            : this.states;
    }

    displayFn(value: any): string {
        return value && typeof value === 'object' ? value.text : value;
    }

}
