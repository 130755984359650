import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, Inject } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
    selector: 'app-proposta',
    templateUrl: 'proposta.component.html',
    styleUrls: ['./proposta.component.css']
})
export class PropostaComponent {
    data = {
        nome: null,
        contato: null,
        empresa: null,
        endereco: null,
        email: null,
        relato: null,
        checked: null,
        accept: null,
    };
    lista = [
        { descricao: 'Instituição de Ensino' },
        { descricao: 'Plataforma de Ensino' },
        { descricao: 'Empresa Privada +1000 a 3500 colaboradores' },
        { descricao: 'Empresa Privada +5000 ou mais  colaboradores' },
        { descricao: 'Empresa Publica / ONG' },
    ];
    constructor(
        public dialogRef: MatDialogRef<PropostaComponent>,
        public utils: UtilsService,
        @Inject(MAT_DIALOG_DATA) public defaults: any,
    ) {
    }

    save() {
        console.log('MODEL: ', this.data);

        let preenchido = true;
        ['nome', 'contato', 'empresa', 'endereco', 'email', 'relato', 'checked', 'accept'].forEach(e => !this.data[e] ? preenchido = false : null);
        if (!preenchido) {
            this.utils.toast().warning();
            return;
        }
        this.utils.dbFire.collection('solicitacoes').add(this.data).then(() => {
            this.utils.toast({ duration: 15000 }).message(`Relatório enviado! Alguém de mossa equipe entrara em contato o mais rápido possível`);
            this.sendEmail(this.data);
            this.dialogRef.close(true);
        }).catch((err) => {
            console.log('Error: ', err);
            this.utils.toast().error();
        })
    }

    sendEmail(data: any) {
      
        const email = {
          subject: 'Solicitação EVOK',
          to: data.email,
          text: `
          Agradecemos por sua participação, estaremos entrando em contato em breve\n
          Atenciosamente\n
          Evok da Equipe10x
          Resumo:
          ${data.nome} (${data.email})\n
          Empresa: ${data.empresa}.\n
          Contato: ${data.contato}.\n
          Endereço: ${data.endereco}.\n
          Relato: ${data.relato}.\n
          `,
          html: `
          Agradecemos por sua solicitação, estaremos entrando em contato em breve
          <br/>
          Atenciosamente,<br>
          <b>Evok da 2Towers</b>
          <br>
          <br>
          Resumo:<br>
          ${data.nome} (${data.email})<br>
            <b>Empresa: </b>${data.empresa}.<br>
            <b>Contato: </b>${data.contato}.<br>
            <b>Endereço:</b> ${data.endereco}.<br>
            <b>Relato:  </b> ${data.relato}.<br>
          `,
        }
        this.utils.mailerPOST(email).finally(() => {
          console.log('Email send');
        });
       
      }
}
