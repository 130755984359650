import { Component, OnInit, ViewChild } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';
import { MatSidenav, MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {
  @ViewChild('sidenav', { static: false }) sidenav: MatSidenav;

  opened = false;

  menu = [];

  constructor(
    public utils: UtilsService,
    public router: Router,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.initializeComponent();
  }

  initializeComponent() {
    this.getEventSide();
    this.getUserInfo();
  }

  async getUserInfo() {
    const nome = this.utils.getStorage('meuNome');
    const token = this.utils.getStorage('meuToken');
    const contato = this.utils.getStorage('meuContato');
    const nascimento = this.utils.getStorage('meuNascimento');
    const accept = this.utils.getStorage('accept');
    if (!nome) {
      this.router.navigate(['/evok']);
    }
    this.utils.user = {
      nome, token,
      email: this.utils.getStorage('meuEmail'),
      contato,
      nascimento,
      accept
    };
    this.menu = [
      // {
      //   name: 'HOME',
      //   action: () => this.goHOME(),
      //   permition: () => true,
      //   icon: 'house'
      // },
      {
        name: 'EVOK',
        action: () => this.goEVOK(),
        permition: () => true,
        icon: 'bookmark'
      },
    ].filter(e => e.permition()).map(element => element);
  }

  getEventSide() {
    this.utils.stateSideNav.subscribe(e => {
      if (this.sidenav) {
        this.colapseSideNav(e);
      }
    });
  }

  goHOME() {
    this.router.navigate(['/home']);
  }


  goVIP() {
    this.router.navigate(['/vip']);
  }


  goEVOK() {
    this.router.navigate(['/evok']);
  }

  colapseSideNav(e) {
    this.opened = e;
    if (this.opened) {
      this.sidenav.open();
    } else {
      this.sidenav.close();
    }
  }

  close(e) {
    this.utils.toggleSideNav(false);
  }
}
