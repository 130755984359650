import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBarConfig, MatSnackBar } from '@angular/material';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { AngularFireAuth } from '@angular/fire/auth';
import { from } from 'rxjs';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
export class Token {
  value: string;
}

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  user: any = {};
  endpoint = environment.endpoint;
  sidenav = false;
  task: AngularFireUploadTask;
  progressDown = 0;
  bufferValue = 0;
  public token: Token = new Token();

  private sideNavObservable = new BehaviorSubject<boolean>(this.sidenav);
  stateSideNav = this.sideNavObservable.asObservable();

  constructor(
    private snackBar: MatSnackBar,
    private router: Router,
    public dbFire: AngularFirestore,
    public storageFire: AngularFireStorage,
    public messagingFire: AngularFireMessaging,
    public firebaseAuth: AngularFireAuth,
    public http: HttpClient,
  ) { }

  setStorage(parametro: string, valor) {
    window.localStorage.setItem(parametro, valor);
  }

  getStorage(parametro: string): any {
    return window.localStorage.getItem(parametro);
  }

  removeStorage(parametro: string) {
    window.localStorage.removeItem(parametro);
  }

  loading(active) {
    // this.toast()
  }


  getToken(): Observable<Token> {
    const observable = from(this.syncToken());
    return observable;
  }

  createToken() {
    return (
      this.s4() + this.s4() + this.s4() + this.s4()
    ).toUpperCase().substr(0, 8);
  }

  uId() {
    return (
      this.s4() + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + this.s4() + this.s4()
    );
  }

  destroy(object) {
    Object.keys(object || {}).filter((e) => !object || object[e]).map(key =>
      object[key].unsubscribe()
    );
  }

  s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  getFireUrl(reference) {
    return this.storageFire.ref(reference).getDownloadURL();
  }

  deleteFile(reference) {
    return this.storageFire.ref(reference).delete();
  }

  syncToken(): Promise<Token> {
    return new Promise((resolve, reject) => {
      this.firebaseAuth.auth.currentUser
        .getIdToken(true)
        .then(idToken => {
          this.token.value = idToken;
          resolve(this.token);
        }).catch(error => {
          reject(error);
        });
    });
  }

  uploadFile(path, file: { archive?: File; base64?: any; }) {
    return new Promise((resolve, reject) => {
      if (file.base64) {
        const ref = this.storageFire.ref(path);
        this.task = ref.putString(file.base64, 'base64');
      } else {
        this.task = this.storageFire.upload(path, file.archive);
      }
      this.task.snapshotChanges().subscribe(snap => {
        this.bufferValue = snap.totalBytes;
        if (snap.bytesTransferred === snap.totalBytes) {
          this.progressDown = 0;
          this.bufferValue = 0;
          resolve({ snap });
        }
      }, err => {
        this.progressDown = 0;
        this.bufferValue = 0;
        if (err.code_ === 'storage/canceled') {
          resolve({ err: 'CANCELADO' });
        } else {
          reject({ err });
        }
      });
      this.task.percentageChanges().subscribe(percent => {
        this.progressDown = percent;
      });
    });
  }

  toggleSideNav(active = !this.sidenav) {
    console.log('toggleSideNav');
    this.sidenav = active;
    this.sideNavObservable.next(active);
  }

  toast(config = { duration: 1500 }) {
    const snackbarconfig: MatSnackBarConfig = {
      duration: config.duration
    };
    return {
      success: () => this.snackBar.open('Realizado com sucesso', 'fechar', snackbarconfig),
      error: () => this.snackBar.open('Falha ao realizar', 'fechar', snackbarconfig),
      warning: () => this.snackBar.open('há campos sem preencher', 'fechar', snackbarconfig),
      message: (message, action = 'fechar') => this.snackBar.open(message, action, snackbarconfig)
    };
  }

  sure(dialogRef) {
    const cn = confirm('Os dados preenchidos serão perdidos deseja continuar ?');
    if (cn) {
      dialogRef.close();
    }
  }

  resetPassword(user) {
    return this.firebaseAuth.auth.sendPasswordResetEmail(user.email);
  }

  createUser(email) {
    const password = 'equipe10x' + email;
    return this.firebaseAuth.auth.createUserWithEmailAndPassword(email, password);
  }

  login(email) {
    const password = 'equipe10x' + email;
    return this.firebaseAuth.auth.signInWithEmailAndPassword(email, password);
  }

  
  mailerPOST(contents) {
    return this.dbFire.collection('envios').add(contents);
  }

  logout() {
    this.firebaseAuth.auth.signOut().finally(() => {
      this.router.navigate(['/']);
      this.setStorage('meuNome', null);
      this.setStorage('meuEmail', null);
      this.setStorage('meuToken', null);
    });
  }

  httpGET(route, param?) {
    return this.http.get(route, param);
  }

  httpPOST(route, param) {
    const headers = new HttpHeaders();
    param.api = 'bcjnjykkuyzgcbxs';
    return this.http.post(route, param, { headers, observe: 'response' });
  }
}
