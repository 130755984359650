import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, Inject } from '@angular/core';

@Component({
    selector: 'app-dialog-confirm',
    templateUrl: 'dialog-confirm.component.html',
    styleUrls: ['./dialog-confirm.component.css']
})
export class DialogConfirmComponent {

    title = 'Desconectar';
    description = 'Deseja realmente continuar?';
    optionTrue = 'Sim';
    optionFalse = 'Não';

    constructor(
        public dialogRef: MatDialogRef<DialogConfirmComponent>,
        @Inject(MAT_DIALOG_DATA) public defaults: any,
    ) {
        if (defaults && defaults.title) {
            this.title = this.defaults.title;
        }
        if (defaults && defaults.description) {
            this.description = this.defaults.description;
        }
        if (defaults && defaults.optionTrue) {
            this.optionTrue = this.defaults.optionTrue;
        }
        if (defaults && defaults.optionFalse) {
            this.optionFalse = this.defaults.optionFalse;
        }
    }
}
