import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor() {
    const splash = document.getElementById('app-splash-screen');
    if (splash) {
      setTimeout(() => {
        splash.remove();
      }, 1000);
    }
  }
}
